
import Vue from 'vue';
import Component from 'vue-class-component';
import isEmpty from 'lodash/isEmpty';

import {
  Slip,
  SlipStatus,
  SlipStore,
  OddStatus,
  slipService,
  SLIP_STATUS_COLORS,
  SLIP_DENIAL_BY,
  SlipType,
} from '@/modules/slip';
import { CURRENT_RESULT_KEYS_BY_SPORT, PERIOD_RESULT_KEYS_BY_SPORT } from '@/modules/event';
import { SportStore } from '@/modules/sport';

import Tape from '@/ui/components/Tape';
import { Prop } from 'vue-property-decorator';
import { TicketOdd } from '@/modules/slipApproval';
import { EUROPEAN_FULL_DATE_TIME } from '@/constants';

@Component({ components: { Tape } })
export default class SlipDetails extends Vue {
  odds: any = {}; // dirty hack to cache odds
  showMoreDetails = false;

  @Prop({ required: true })
  slip!: Slip;

  @Prop({ required: true })
  onClose!: () => void;

  @Prop({ required: false, default: false })
  showCancelSlip!: boolean;

  get slipDetails() {
    return [
      { key: 'short_uuid', label: this.$t('slip.slipId') },
      {
        label: this.$t('slip.status'),
        parsedValue: SLIP_STATUS_COLORS[this.slip.status]?.text,
        style: `color: ${slipService.getStatusColor(this.slip.status).color};`,
      },
      { key: 'created_at', label: this.$t('slip.date'), type: 'date' },
      {
        label: this.$t('slip.amountSent'),
        type: 'currency',
        parsedValue: this.slip?.old_amount ? this.slip.old_amount : this.slip.amount,
      },
      { key: 'amount', label: this.$t('slip.amountAccepted'), type: 'currency' },
      {
        key: 'max_winning_amount',
        label: !this.isWonOrCashback
          ? this.$t('slip.potentialWinnings')
          : this.$t('common.maxWinningAmount'),
        type: 'currency',
      },
      {
        key: 'max_bonus_amount',
        label: !this.isWonOrCashback
          ? this.$t('slip.potentialBonus')
          : this.$t('slip.maxBonusAmount'),
        type: 'currency',
      },
      {
        key: 'jackpot',
        label: !this.isWonOrCashback ? this.$t('slip.potentialJackpot') : this.$t('slip.jackpot'),
      }, // NOT IMPLEMENTED
      {
        label: !this.isWonOrCashback
          ? this.$t('slip.potentialActualWinning')
          : this.$t('common.actualWinningAmount'),
        type: 'currency',
        parsedValue:
          this.slip.status === SlipStatus.CASHBACK
            ? //@ts-ignore
              this.slip.cash_back
            : this.slip.actual_winning_amount || this.slip.max_winning_amount,
      },

      { key: 'number_of_matches', label: this.$t('common.numOfMatches') },
      {
        label: this.$t('common.numOfCombinations'),
        parsedValue: this.isSystem ? this.slip.number_of_combinations : '-',
      },
      {
        label: this.$t('common.user'),
        parsedValue: this.slip.slip_env === 'web' ? this.slip?.username : '-',
      },
      {
        label: this.$t('common.bettingPlaceId'),
        //@ts-ignore
        parsedValue: this.slip.slip_env === 'web' ? 'WEB' : this.slip.betting_place,
      }, // CHECK FOR MOBILE & KLADOMAT
      {
        label: this.$t('slip.paymentAcceptedBy'),
        // parsedValue:
        //   this.slip.slip_env === 'web' ? '-' : this.slip.betting_machine_id || this.slip.username, // username is operator on SC slip, but for name bp machine and bp is same
        parsedValue: this.slip.slip_env === 'web' ? '-' : this.slip.username, // so we use it like this for now until betting machine id is kladomat id
      },
      {
        label: this.$t('slip.paidOutAt'),
        parsedValue:
          this.slip.slip_env === 'web' && this.slip.status === SlipStatus.PAYED_OUT
            ? 'WEB'
            : //@ts-ignore
              this.slip.payout_shop_client_name, // username is operator on SC slip
      },
      {
        label: this.$t('slip.paidOutBy'),
        parsedValue:
          this.slip.slip_env === 'web' && this.slip.status === SlipStatus.PAYED_OUT
            ? '-'
            : //@ts-ignore
              this.slip.payout_user_name, // username is operator on SC slip
      },

      {
        label: this.$t('slip.sentForApproval'),
        parsedValue: this.slip.for_approving ? this.slip.created_at : null,
        type: 'date',
      }, // CHANGE TO TIME OF APPROVAL?
      { key: 'approval_reason', label: this.$t('common.approvalReason') },
      {
        label: this.$t('slip.approvalOperator'),
        parsedValue: this.slip.for_approving
          ? //@ts-ignore
            this.slip.approved_by_user || this.slip.manual_denied_by_name
          : '-',
      }, // parse merge approved by denied by
      { key: 'approved_time', label: this.$t('common.approvedTime'), type: 'date' },
      { key: 'manual_denied_time', label: this.$t('slip.deniedTime'), type: 'date' },
      {
        label: this.$t('slip.deniedBy'),
        //@ts-ignore
        parsedValue: SLIP_DENIAL_BY[this.slip.denied_by],
      },
      { key: 'resolving_time', label: this.$t('slip.resolvingTime'), type: 'date' },
      { key: 'resolving_operator_name', label: this.$t('slip.resolvingName') },
      { key: 'player_risk', label: this.$t('slip.riskLevel') },
    ];
  }

  get isWonOrCashback() {
    if (!this.slip) return false;
    return [SlipStatus.PASSED, SlipStatus.PAYED_OUT, SlipStatus.CASHBACK].includes(
      this.slip.status
    );
  }

  get slipErrors() {
    if (!this.slip) return [];
    return this.slip?.denial_reason?.errors || [];
  }

  get hasDenialReason() {
    if (!this.slip) return false;
    return Object.keys(this.slip.denial_reason).length > 0;
  }

  get slipStatusConfig() {
    return slipService.getStatusColor(this.slip?.status);
  }

  get isSystem() {
    if (!this.slip) return false;
    return this.slip.is_system;
  }

  get paymentPerCombination() {
    if (!this.slip) return false;
    return this.isSystem ? (this.slip.amount / this.slip.number_of_combinations)?.toFixed(2) : '-';
  }

  getEventResult(event: any) {
    if (isEmpty(event.event_result)) {
      return this.$t('common.notAvailable');
    }
    let currentResult = '';
    let firstPeriodResult = '';

    const sportCode = SportStore.getSportById(event.sport_id)?.code;
    const currentResultKeys = CURRENT_RESULT_KEYS_BY_SPORT[sportCode];
    const periodResultKeys = PERIOD_RESULT_KEYS_BY_SPORT[sportCode];
    if (isEmpty(event.event_result.current_result)) return this.$t('common.notAvailable');
    if (currentResultKeys) {
      currentResult =
        event.event_result.current_result[currentResultKeys[0].key][0] +
        '-' +
        event.event_result.current_result[currentResultKeys[0].key][1];
    }
    if (periodResultKeys) {
      firstPeriodResult =
        event.event_result.detailed_result[periodResultKeys[0]?.key]?.[
          periodResultKeys[0]?.resultKey
        ]?.[0] +
        '-' +
        event.event_result.detailed_result[periodResultKeys[0]?.key]?.[
          periodResultKeys[0]?.resultKey
        ]?.[1];
    } else {
      return currentResult;
    }
    return firstPeriodResult.includes('undefined')
      ? currentResult
      : `${currentResult} (${firstPeriodResult}) `;
  }

  systemFix(slip: Slip) {
    if (slip.is_system) {
      return (
        this.$t('slipApproval.slipTypeSystem') +
        slip.slip_groups.map((group: any) => group.system).join(' , ')
      );
    }
    return this.$t('slipApproval.slipTypeFix') + slip.slip_groups[0].system;
  }

  getValue(data: any) {
    if (!this.slip) return;
    const key = data.key as keyof Slip;
    const value = this.slip[key];
    const filter = this.$options?.filters?.[data.type];
    const filterModifier = data.type === 'date' ? EUROPEAN_FULL_DATE_TIME : undefined;
    if (data.parsedValue) {
      return filter ? filter(data.parsedValue, filterModifier) : data.parsedValue;
    }

    if (!value) return '-';
    return filter ? filter(value, filterModifier) : value;
  }

  getOddType(oddId: string) {
    if (this.odds[oddId]) {
      return this.odds[oddId].outcome_print_code;
    }
    return 'N/A';
  }

  setOddValue(odd: any) {
    Vue.set(this.odds, odd.odd_id, odd);
  }

  parseEventType(eventType: string, eventStage: string) {
    if (eventType === 'P') return '';
    if (eventType === 'D' && (eventStage === 'prematch' || eventStage === 'PREMATCH')) return '';
    if (eventType === 'D' && eventStage === 'live') return 'L';
    if (eventType === 'A') return 'O';
    return '';
  }

  getEventTypeStyle(eventType: string, eventStage: string) {
    return `width: 30px; vertical-align: baseline; color:${this.parseEventTypeColor(
      eventType,
      eventStage
    )}`;
  }

  parseEventTypeColor(eventType: string, eventStage: string) {
    if (eventType === 'D' && eventStage === 'live') return 'red';
    if (eventType === 'A') return 'blue';
    return '';
  }

  async cancelSlip() {
    await SlipStore.cancelSlip(this.slip.short_uuid);
    this.$emit('slip-canceled', this.slip.short_uuid);
  }

  canCancelSlip() {
    return (
      this.slip.status === SlipStatus.NOT_RESOLVED &&
      this.slip.slip_type === SlipType.LIVE &&
      this.slip.slip_env === 'landbase'
    );
  }

  toggleShowMore() {
    this.showMoreDetails = !this.showMoreDetails;
  }

  close() {
    this.onClose();
    this.showMoreDetails = false;
  }

  wonOdd(odd: TicketOdd) {
    if (
      odd.automated_status === OddStatus.PASSED ||
      odd.manual_status === OddStatus.PASSED ||
      odd.odd_value === 1
    )
      return true;
    return false;
  }

  lostOdd(odd: TicketOdd) {
    if (odd.automated_status === OddStatus.FAILED || odd.manual_status === OddStatus.FAILED)
      return true;
    return false;
  }
}
