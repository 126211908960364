
import { Component, Vue } from 'vue-property-decorator';
import { ICellRendererParams } from '@ag-grid-community/core';

import { REPORTS_SLIP_STATUS_OPTIONS } from '@/modules/slip/slip.constants';
import { SLIP_STATUS_COLORS } from '@/modules/slip/slip.constants';

@Component
export default class SlipTypeMap extends Vue {
  params: ICellRendererParams | undefined;

  get ticketStatusFromData() {
    if (!this.params?.data) return '';
    return this.params?.data.status;
  }

  get mappedTicketStatus() {
    if (!this.ticketStatusFromData) return '';
    return REPORTS_SLIP_STATUS_OPTIONS.find(type => type.value === this.ticketStatusFromData)
      ?.label;
  }

  get ticketStatusColor() {
    return (SLIP_STATUS_COLORS as any)[this.ticketStatusFromData] || { text: '', textColor: '' };
  }
}
