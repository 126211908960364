import { ICellRendererParams } from '@ag-grid-community/core';

const CurrencyCell = ({ value }: ICellRendererParams) => {
  const valueToNumber = Number(value);
  if (typeof valueToNumber !== 'number') return '';

  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valueToNumber);
};

export default CurrencyCell;
