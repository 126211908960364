
import { Mixins, Component, Watch } from 'vue-property-decorator';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';

import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { WithFilters } from '@/ui/mixins/WithFilters';
import CurrencyCell from '@/ui/components/CellRenderers/CurrencyCell';
import moment from 'moment';

import {
  SlipStore,
  REPORT_TYPES,
  SLIP_TYPES,
  SLIP_ENV,
  REPORTS_SLIP_STATUS_OPTIONS,
  SlipType,
  EVENT_TYPES,
} from '@/modules/slip';
import { SportStore } from '@/modules/sport';
import { mapForSelect } from '@/lib';
import DateTimeCell from '@/ui/components/CellRenderers/DateTimeCell';
import UsernameOrPlace from '@/ui/components/CellRenderers/UsernameOrPlace';
import { MarketStore } from '@/modules/market';
import { OutcomeStore } from '@/modules/outcome';
import { DATE } from '@/constants';
import SlipDetails from './components/SlipDetails.vue';
import odd from '@/ui/filters/odd';
import { SingleSlipStore } from '@/modules/singleSlip/singleSlip.store';
import SlipTypeMap from '@/ui/components/CellRenderers/SlipType.vue';
import { FeedStore } from '@/modules/feed/feed.store';
import { RowNode } from '@ag-grid-community/core';

const allEventsCollected = [];

@Component({ components: { SlipDetails, SlipTypeMap } })
export default class SlipPage extends Mixins(WithDataTable, WithFilters) {
  reportTypes = REPORT_TYPES;
  slipTypes = SLIP_TYPES;
  eventTypes = EVENT_TYPES;
  slipEnv = SLIP_ENV;
  shortDateFormat = DATE;
  form!: WrappedFormUtils;
  sport = 0;
  marketGroup = 0;
  market = 0;
  isLoading = false;
  canCancelSlip = false;
  eventId: any = '';
  eventType: any = '';
  slipType: any = '';
  rowHeight = 0;
  apiCalled = false;

  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('slip.date'),
          field: 'created_at',
          cellRenderer: DateTimeCell,
          cellRendererParams: {
            date_type: 'european_date_time',
          },
          width: 140,
          sortable: true,
        },
        { headerName: this.$t('slip.slipId'), field: 'short_uuid', width: 90 },
        {
          headerName: this.$t('slip.userPlace'),
          width: 100,
          field: 'username',
          cellRenderer: UsernameOrPlace,
          sortable: true,
        },
        { headerName: this.$t('slip.type'), field: 'slip_type', width: 90, sortable: true },
        {
          headerName: this.$t('slip.numberOfMatches'),
          field: 'number_of_matches',
          width: 100,
          sortable: true,
        },
        {
          headerName: this.$t('slip.payment'),
          field: 'amount',
          width: 100,
          sortable: true,
          valueFormatter: CurrencyCell,
        },
        {
          headerName: this.$t('slip.maxWinningAmount'),
          field: 'max_winning_amount',
          width: 100,
          sortable: true,
          valueFormatter: CurrencyCell,
        },
        {
          headerName: this.$t('slip.minOddsValue'),
          field: 'min_odds_value',
          sortable: true,
          valueFormatter: (params: any) => odd(params.value),
          width: 80,
        },
        {
          headerName: this.$t('slip.maxOddsValue'),
          field: 'max_odds_value',
          valueFormatter: (params: any) => odd(params.value),
          width: 80,
          sortable: true,
        },
        {
          headerName: this.$t('slip.status'),
          width: 140,
          sortable: true,
          cellRenderer: 'SlipTypeMap',
        },
        { headerName: this.$t('slip.note'), field: 'note' },
        { headerName: this.$t('slip.risk'), field: 'player_risk' },
      ],
      // rowModelType: 'infinite',
      rowData: this.tableData,
      cacheBlockSize: 50,
      rowBuffer: 50,
      getRowHeight: this.getRowHeight,
      suppressScrollOnNewData: true,
    };
  }

  get gridOptions() {
    return {
      onBodyScroll: this.handleScrollPagination,
      suppressScrollOnNewData: true,
    };
  }

  get isInfinite() {
    return false;
  }

  get sports() {
    const sortedSports = SportStore.sortedAllSports;
    return mapForSelect(sortedSports);
  }

  get tableData() {
    return SlipStore.slips;
  }

  get totalRecords() {
    return SlipStore.totalSlips;
  }

  get slipStatus() {
    return REPORTS_SLIP_STATUS_OPTIONS;
  }

  get marketGroups() {
    const marketGroups = MarketStore.singleSportMarketGroups(this.sport);
    return mapForSelect(marketGroups);
  }

  get markets() {
    const marketGroups = MarketStore.singleSportMarketGroups(this.sport);
    const marketGroup = marketGroups.find((m: any) => m.id === this.marketGroup);
    if (!marketGroup) return [];
    return mapForSelect(marketGroup.markets);
  }

  get outcomes() {
    return mapForSelect(OutcomeStore.singleMarketOutcomes(this.market));
  }

  get selectedSlip() {
    return SlipStore.selectedSlip;
  }

  get isPrematchSlip() {
    return SlipStore.selectedSlip?.slip_type === SlipType.LIVE;
  }

  get newSlipAdded() {
    return SlipStore.newSlipAdded;
  }

  get lastUpdatedSlip() {
    return SlipStore.lastUpdatedSlip;
  }

  getRowHeight(value: any) {
    if (!value) return;
    this.rowHeight = value.node.rowHeight;
  }

  filterArrivedSlip(slip: any) {
    const filterData = this.form.getFieldsValue();
    const dateOfArrivedSlip = moment(slip.created_at);
    let otherFilterActive = false;

    Object.keys(filterData).forEach(filterField => {
      if (filterField !== 'from' && filterField !== 'to' && filterField !== 'status') {
        if (filterData[filterField]) {
          otherFilterActive = true;
          return;
        }
      }
    });

    if (otherFilterActive) return false;
    const dateFilterFrom = filterData.from.date
      ? moment(
          `${filterData.from.date} ${filterData.from.hour ?? '00'}:${filterData.from.minute ??
            '00'}:${filterData.from.second ?? '00'}`,
          'YYYY-MM-DD hh:mm:ss'
        )
      : false;

    const dateFilterTo = filterData.to.date
      ? moment(
          `${filterData.to.date} ${filterData.to.hour ?? '00'}:${filterData.to.minute ??
            '00'}:${filterData.to.second ?? '00'}`,
          'YYYY-MM-DD hh:mm:ss'
        )
      : false;

    const iSameState = filterData.status ? slip.status === filterData.status : true;
    let isInRange = true;
    if (dateFilterFrom && dateFilterTo) {
      isInRange = dateOfArrivedSlip.isBetween(dateFilterFrom, dateFilterTo, 'minutes', '[]');
    }
    if (!dateFilterFrom && dateFilterTo) {
      isInRange = dateFilterTo.isBefore(dateOfArrivedSlip);
    }
    if (dateFilterFrom && !dateFilterTo) {
      isInRange = dateOfArrivedSlip.isAfter(dateFilterFrom);
    }

    return iSameState && isInRange;
  }

  @Watch('newSlipAdded')
  async handleSlipAdded(data: any) {
    if (!this.filterArrivedSlip(data)) return;
    SlipStore.slips.unshift(data);
    await this.gridApi?.applyTransaction({ add: [data] });
    const rowNode = this.gridApi?.getDisplayedRowAtIndex(0) as RowNode;
    this.gridApi?.flashCells({ rowNodes: [rowNode] });
  }

  @Watch('lastUpdatedSlip')
  redrawSlip(data: string) {
    const [shortUuid] = data.split('_');
    const foundEvent = SlipStore.slips.find(s => s.short_uuid === shortUuid);
    if (foundEvent) {
      allEventsCollected.push(foundEvent);
      // @ts-ignore
      this.gridApi?.forEachNode(node => {
        if (node.data.short_uuid == shortUuid) {
          node.setData(foundEvent);
          // @ts-ignore
          this.gridApi?.redrawRows({ rowNodes: [node] });
          this.gridApi?.flashCells({ rowNodes: [node] });
        }
      });
    }
  }

  handleScrollPagination(event: any) {
    const lastTenEvents = 10;
    const visibleRowsOnPage = 17.6 * this.rowHeight;
    const lastTenToPreview = this.rowHeight * lastTenEvents + visibleRowsOnPage; // default value for 17.6 rows;
    const maxHeight = this.tableData.length * this.rowHeight;
    const triggerScroll = event.top + lastTenToPreview > maxHeight;
    if (maxHeight && !this.apiCalled && event.direction === 'vertical' && triggerScroll) {
      this.apiCalled = true;
      this.fetchData(undefined, true);
    }
  }

  clearSelectedSlip() {
    SlipStore.setActiveSlip('');
  }

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  beforeDestroy() {
    FeedStore.disconect();
  }

  mounted() {
    SlipStore.clearStore();
    FeedStore.init();
    SportStore.fetchSports();
    SingleSlipStore.getDeniedPayoutTicketNumber();
    if (this.$route.query.event_id) {
      this.form.setFieldsValue({ int_key: this.$route.query.event_id });
      this.eventId = this.$route.query.event_id;
    }
    if (this.$route.query.slip_type) {
      this.form.setFieldsValue({ slip_type: this.$route.query.slip_type });
      this.slipType = this.$route.query.slip_type;
    }
    if (this.$route.query.event_type) {
      this.form.setFieldsValue({ event_type: this.$route.query.event_type });
      this.eventType = this.$route.query.event_type;
    }
    this.fetchData();
  }

  destory() {
    FeedStore.disconect();
  }

  async onFilterSubmit(e: any) {
    if (this.isLoading) return;
    e.preventDefault();
    await SlipStore.clearStore();
    this.gridApi?.showLoadingOverlay();
    this.fetchData();
  }

  async onRowClicked({ data }: any) {
    await this.fetchSlipToUpdate(data.short_uuid);
    SlipStore.setActiveSlip(data.id);
  }

  onSportSelect(selectedSport: number) {
    if (!selectedSport) {
      this.form.resetFields(['market_id', 'group_id', 'outcome_id']);
      this.market = 0;
      this.marketGroup = 0;
      return;
    }
    this.sport = selectedSport;
    MarketStore.getSportMarketGroups(selectedSport);
    this.form.resetFields(['market_id', 'group_id', 'outcome_id']);
    this.market = 0;
    this.marketGroup = 0;
  }

  onMarketGroupSelect(marketGroupId: number) {
    this.marketGroup = marketGroupId;
    this.market = 0;
    this.form.resetFields(['market_id', 'outcome_id']);
  }

  onMarketSelect(marketId: number) {
    this.market = marketId;
    OutcomeStore.getMarketOutcomes(marketId);
  }

  async fetchSlipToUpdate(shortUuid: string) {
    await SlipStore.fetchSlipToUpdate({
      short_uuid: shortUuid,
    });
  }

  async fetchData(sort?: string[], fetchPagination = false) {
    if (!!this.totalRecords && this.totalRecords <= this.tableData.length) return;
    const ordering = sort?.length ? { ordering: sort[0] } : { ordering: '-created_at' };
    const filterData = this.form.getFieldsValue();
    this.gridApi?.showLoadingOverlay();
    this.isLoading = true;
    await SlipStore.fetchSlips({
      limit: 50,
      offset: this.tableData.length,
      ...filterData,
      ...ordering,
    });
    this.isLoading = false;
    if (fetchPagination) {
      this.apiCalled = false;
    }
  }

  async clearFilters() {
    this.form.resetFields();
    await SlipStore.clearStore();
    this.gridApi?.showLoadingOverlay();
    this.fetchData();
  }

  async sortUpdated(sort: string[]) {
    await SlipStore.clearStore();
    this.fetchData(sort);
  }

  onSlipCancel() {
    this.fetchData(this.sortModel);
  }
}
