import { ICellRendererParams } from '@ag-grid-community/core';
import isUndefined from 'lodash/isUndefined';

import { NO_VALUE } from '@/constants';
import { slipService } from '@/modules/slip';
import { Slip } from '@/modules/slip/slip.types';

const UsernameOrPlace = ({ data }: ICellRendererParams) => {
  if (isUndefined(data)) return NO_VALUE;
  return slipService.getUsernameOrPlace(data as Slip);
};

export default UsernameOrPlace;
